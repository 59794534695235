import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Navmenu } from "../../components/navmenu";
import { Input } from "../../components/input";
import { Textarea } from "../../components/textarea";
import { Date } from "../../components/date";
import { FormButtons } from "../../components/form-buttons";

import { stickDate } from "../../utils/stick-date";
import { createCodeRequest } from "../../store/codes/actions";

export const CreateCode = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [values, setValues] = useState({ visits: "0" });

    const dateNow = new window.Date();
    const [date, setDate] = useState({
        year: dateNow.getFullYear(),
        month: dateNow.getMonth() + 1,
        day: dateNow.getDate(),
        hour: dateNow.getHours(),
        minute: dateNow.getMinutes(),
        second: dateNow.getSeconds(),
    });

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    const onCreate = () => {
        dispatch(createCodeRequest(stickDate(values, date, "date")));
        history.goBack();
    };

    return (
        <div className="page">
            <Navmenu />
            <main className="main">
                <form className="content__wrapper">
                    <Input
                        title="Url"
                        value={values?.url}
                        onFieldChange={onFieldChange}
                        name="url"
                        placeholder="Url"
                    />
                    <Input
                        title="Visits"
                        value={values?.visits}
                        onFieldChange={onFieldChange}
                        name="visits"
                        placeholder="Visits"
                    />
                    <Textarea
                        title="Code"
                        value={values?.code}
                        onFieldChange={onFieldChange}
                        name="code"
                        placeholder="Code"
                    />
                    <Date
                        date={values?.date}
                        values={date}
                        setValues={setDate}
                        disableOffset
                    />
                    <FormButtons onCreate={onCreate} />
                </form>
            </main>
        </div>
    );
};
