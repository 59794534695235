import { put, call, takeLatest } from "redux-saga/effects";
import Cookies from "js-cookie";

import { ENDPOINTS } from "../endpoints";
import { TYPES } from "../types";
import {
    loginSuccess,
    loginFailure,
    checkTokenSuccess,
    checkTokenFailture,
} from "./actions";

import { axiosInstance } from "../../utils/axios-instance";

// запрос на вход
function* login({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.post,
            ENDPOINTS.LOGIN_ENDPOINT,
            values
        );
        Cookies.set("token", data.token);

        yield put(loginSuccess(data));
    } catch (err) {
        yield put(loginFailure(err));
    }
}

// Проверка JWT токена
function* checkToken({ token }) {
    try {
        const { data } = yield call(
            axiosInstance.post,
            ENDPOINTS.CHECK_TOKEN_ENDPOINT,
            { token }
        );
        yield put(checkTokenSuccess(data));
    } catch {
        yield put(checkTokenFailture());
    }
}

export default function* loginSaga() {
    yield takeLatest(TYPES.LOGIN_REQUEST, login);
    yield takeLatest(TYPES.CHECK_TOKEN_REQUEST, checkToken);
}
