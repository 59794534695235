import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Navmenu } from "../../components/navmenu";
import { RadioButtons } from "../../components/radio-buttons";
import { Input } from "../../components/input";
import { Textarea } from "../../components/textarea";
import { Date } from "../../components/date";
import { FormButtons } from "../../components/form-buttons";

import { createRecipeRequest } from "../../store/recipes/actions";
import { stickDate } from "../../utils/stick-date";

export const CreateRecipe = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [values, setValues] = useState({ visits: "0" });

    const dateNow = new window.Date();
    const [date, setDate] = useState({
        year: dateNow.getFullYear(),
        month: dateNow.getMonth() + 1,
        day: dateNow.getDate(),
        hour: dateNow.getHours(),
        minute: dateNow.getMinutes(),
        second: dateNow.getSeconds(),
    });

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    const onCreate = () => {
        dispatch(createRecipeRequest(stickDate(values, date, "date")));
        history.goBack();
    };

    const types = [
        "sweet",
        "independent",
        "meat",
        "garnish",
        "salad",
        "product",
    ];

    return (
        <div className="page">
            <Navmenu />
            <main className="main">
                <form className="content__wrapper">
                    <RadioButtons
                        title="Types"
                        items={types}
                        obj={values}
                        field="type"
                    />
                    <Input
                        title="Url"
                        value={values?.url}
                        onFieldChange={onFieldChange}
                        name="url"
                        placeholder="Url"
                    />
                    <Input
                        title="Name"
                        value={values?.name}
                        onFieldChange={onFieldChange}
                        name="name"
                        placeholder="Name"
                    />
                    <Input
                        title="Source"
                        value={values?.source}
                        onFieldChange={onFieldChange}
                        name="source"
                        placeholder="Source"
                    />
                    <Textarea
                        title="Ingredients"
                        value={values?.ingredients}
                        onFieldChange={onFieldChange}
                        name="ingredients"
                        placeholder="Ingredients for cooking"
                    />
                    <Textarea
                        title="Cooking"
                        value={values?.cooking}
                        onFieldChange={onFieldChange}
                        name="cooking"
                        placeholder="Cooking process"
                    />
                    <Input
                        title="Visits"
                        value={values?.visits}
                        onFieldChange={onFieldChange}
                        name="visits"
                        placeholder="Visits"
                    />
                    <Date
                        date={values?.date}
                        values={date}
                        setValues={setDate}
                        disableOffset
                    />
                    <FormButtons onCreate={onCreate} />
                </form>
            </main>
        </div>
    );
};
