import { TYPES } from "../types";

const initialState = {
    isLoading: true,
    isError: false,
    codes: [],
    code: "",
};

export const codesReducer = (state = initialState, action) => {
    switch (action.type) {
        // Получение всех кодов
        case TYPES.GET_CODES_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.GET_CODES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                codes: action.codes,
            };

        // Получение кодов
        case TYPES.GET_CODE_REQUEST:
            return { ...state, isLoading: true, isError: false };
        case TYPES.GET_CODE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                code: action.values,
            };

        // Создание кодов
        case TYPES.CREATE_CODE_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.CREATE_CODE_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // Создание кодов
        case TYPES.PATCH_CODE_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.PATCH_CODE_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // Удаление кодов
        case TYPES.REMOVE_CODE_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.REMOVE_CODE_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // SQL запрос
        case TYPES.SEND_SQL_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.SEND_SQL_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // FAILURE
        case TYPES.FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                Error: action.error,
            };

        default:
            return state;
    }
};
