import React from "react";

import { NavmenuItem } from "./navmenu-item";

import "./navmenu.sass";

export const Navmenu = () => (
    <div>
        <section className="navmenu__wrapper">
            <NavmenuItem link="/links" text="Links" />
            <NavmenuItem link="/admin-links" text="Admin links" />
            <NavmenuItem link="/files" text="Files" />
            <NavmenuItem link="/codes" text="Raw codes" />
            <NavmenuItem link="/recipes" text="Recipes" />
            <NavmenuItem link="/users" text="Users" />
            <NavmenuItem link="/chat" text="Chat" />
            <NavmenuItem link="/sql" text="SQL" />
        </section>
    </div>
);
