import { TYPES } from "../types";

// Логин
export const loginRequest = (values) => ({
    type: TYPES.LOGIN_REQUEST,
    values,
});

export const loginSuccess = (values) => ({
    type: TYPES.LOGIN_SUCCESS,
    userId: values.userId,
    token: values.token,
});

// Ошибка
export const loginFailure = (error) => ({
    type: TYPES.LOGIN_FAILTURE,
    error,
});

// Проверка JWT токена
export const checkTokenRequest = (token) => ({
    type: TYPES.CHECK_TOKEN_REQUEST,
    token,
});
export const checkTokenSuccess = ({ isValid }) => ({
    type: TYPES.CHECK_TOKEN_SUCCESS,
    isValid,
});

export const checkTokenFailture = () => ({
    type: TYPES.CHECK_TOKEN_FAILTURE,
});
