import React from "react";
import { Link } from "react-router-dom";

import "./record.sass";

export const Record = ({ id, title, subtitle, link, url }) => (
    <Link to={`/${link}/${url || title}`}>
        <div
            className="record"
            onContextMenu={(e) => {
                e.preventDefault();
                window.location.replace(subtitle);
            }}
        >
            <p className="record__title">{title}</p>
            {id && <span className="record__id">( {id} )</span>}
            {subtitle && <p className="record__subtitle">{subtitle}</p>}
        </div>
    </Link>
);
