import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { Loading } from "../../components/loading";
import { Navmenu } from "../../components/navmenu";
import { RadioButtons } from "../../components/radio-buttons";
import { Input } from "../../components/input";
import { Textarea } from "../../components/textarea";
import { Date } from "../../components/date";
import { FormButtons } from "../../components/form-buttons";

import { getRecipeRequest } from "../../store/recipes/actions";
import { patchRecipeRequest } from "../../store/recipes/actions";
import { removeRecipeRequest } from "../../store/recipes/actions";
import { stickDate } from "../../utils/stick-date";

export const Recipe = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { url } = useParams();
    const { recipe, isLoading } = useSelector((state) => state.recipes);

    const [values, setValues] = useState(recipe);
    const [date, setDate] = useState();

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    useEffect(() => {
        setValues(recipe);
    }, [recipe]);

    useEffect(() => {
        dispatch(getRecipeRequest(url));
    }, [dispatch, url]);

    const onSave = () => {
        dispatch(patchRecipeRequest(stickDate(values, date, "date")));
        history.goBack();
    };

    const onDelete = () => {
        dispatch(removeRecipeRequest(url));
        history.goBack();
    };

    const types = [
        "sweet",
        "independent",
        "meat",
        "garnish",
        "salad",
        "product",
    ];

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="page">
                    <Navmenu />
                    <main className="main">
                        <form className="content__wrapper">
                            <RadioButtons
                                title="Types"
                                items={types}
                                obj={values}
                                field="type"
                                active={values?.type}
                            />
                            <Input
                                title="Url"
                                value={values?.url}
                                onFieldChange={onFieldChange}
                                name="url"
                                placeholder="Url"
                            />
                            <Input
                                title="Name"
                                value={values?.name}
                                onFieldChange={onFieldChange}
                                name="name"
                                placeholder="Name"
                            />
                            <Input
                                title="Source"
                                value={values?.source}
                                onFieldChange={onFieldChange}
                                name="source"
                                placeholder="Source"
                            />
                            <Textarea
                                title="Ingredients"
                                value={values?.ingredients}
                                onFieldChange={onFieldChange}
                                name="ingredients"
                                placeholder="Ingredients for cooking"
                            />
                            <Textarea
                                title="Cooking"
                                value={values?.cooking}
                                onFieldChange={onFieldChange}
                                name="cooking"
                                placeholder="Cooking process"
                            />
                            <Input
                                title="Visits"
                                value={values?.visits}
                                onFieldChange={onFieldChange}
                                name="visits"
                                placeholder="Visits"
                            />
                            <Date
                                date={values?.date}
                                values={date}
                                setValues={setDate}
                            />
                            <FormButtons onDelete={onDelete} onSave={onSave} />
                        </form>
                    </main>
                </div>
            )}
        </>
    );
};
