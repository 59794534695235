import React from "react";

import { Button } from "../button";

import "./records-panel.sass";

export const RecordsPanel = ({ searchValue, setSearchValue, link }) => (
    <div className="records__panel">
        <input
            className="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search..."
            type="text"
        />
        <Button value="Create" isLink link={link} />
    </div>
);
