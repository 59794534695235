import { TYPES } from "../types";

const initialState = {
    isLoading: true,
    isError: false,
    usersList: [],
    user: {},
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        // Получение всех пользователей
        case TYPES.GET_USERS_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.GET_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                usersList: action.users,
            };

        // Получение пользователя
        case TYPES.GET_USER_REQUEST:
            return { ...state, isLoading: true, isError: false };
        case TYPES.GET_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                user: action.login,
            };

        // Регистрация пользователя
        case TYPES.CREATE_USER_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.CREATE_USER_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // Изменение пользователя
        case TYPES.PATCH_USER_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.PATCH_USER_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // Удаление пользователя
        case TYPES.REMOVE_USER_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.REMOVE_USER_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // FAILURE
        case TYPES.FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                Error: action.error,
            };

        default:
            return state;
    }
};
