import { put, call, fork, takeLatest } from "redux-saga/effects";

import { ENDPOINTS } from "../endpoints";
import { TYPES } from "../types";
import {
    getRecipeSuccess,
    getRecipesSuccess,
    createRecipeSuccess,
    patchRecipeSuccess,
    removeRecipeSuccess,
    requestFailure,
} from "./actions";

import { axiosInstance } from "../../utils/axios-instance";

// Получение всех рецептов
function* getRecipes() {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.RECIPES_ENDPOINT
        );
        yield put(getRecipesSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Получение рецепта
function* getRecipe({ url }) {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.RECIPE_ENDPOINT,
            { params: { url } }
        );
        yield put(getRecipeSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Создание рецепта
function* createRecipe({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.post,
            ENDPOINTS.RECIPE_ENDPOINT,
            values
        );
        yield put(createRecipeSuccess(data));
        yield fork(getRecipes);
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Изменение рецепта
function* patchRecipe({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.patch,
            ENDPOINTS.RECIPE_ENDPOINT,
            values
        );
        yield put(patchRecipeSuccess(data));
        yield fork(getRecipes);
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Удаление рецепта
function* removeRecipe({ url }) {
    try {
        const { data } = yield call(
            axiosInstance.delete,
            ENDPOINTS.RECIPE_ENDPOINT,
            { params: { url } }
        );
        yield fork(getRecipes);
        yield put(removeRecipeSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

export default function* filesSaga() {
    yield takeLatest(TYPES.GET_RECIPE_REQUEST, getRecipe);
    yield takeLatest(TYPES.GET_RECIPES_REQUEST, getRecipes);
    yield takeLatest(TYPES.CREATE_RECIPE_REQUEST, createRecipe);
    yield takeLatest(TYPES.PATCH_RECIPE_REQUEST, patchRecipe);
    yield takeLatest(TYPES.REMOVE_RECIPE_REQUEST, removeRecipe);
}
