import { TYPES } from "../types";

const initialState = {
    isLoading: true,
    isError: false,
    recipes: [],
    recipe: {},
};

export const recipesReducer = (state = initialState, action) => {
    switch (action.type) {
        // Получение всех рецептов
        case TYPES.GET_RECIPES_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.GET_RECIPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                recipes: action.recipes,
            };

        // Получение рецепта
        case TYPES.GET_RECIPE_REQUEST:
            return { ...state, isLoading: true, isError: false };
        case TYPES.GET_RECIPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                recipe: action.values,
            };

        // Создание рецепта
        case TYPES.CREATE_RECIPE_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.CREATE_RECIPE_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // Создание рецепта
        case TYPES.PATCH_RECIPE_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.PATCH_RECIPE_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // Удаление рецепта
        case TYPES.REMOVE_RECIPE_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.REMOVE_RECIPE_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // FAILURE
        case TYPES.FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                Error: action.error,
            };

        default:
            return state;
    }
};
