import { TYPES } from "../types";

// Получение рецепта
export const getRecipeRequest = (url) => ({
    type: TYPES.GET_RECIPE_REQUEST,
    url,
});

export const getRecipeSuccess = (values) => ({
    type: TYPES.GET_RECIPE_SUCCESS,
    values,
});

// Получение всех рецептов
export const getRecipesRequest = () => ({
    type: TYPES.GET_RECIPES_REQUEST,
});

export const getRecipesSuccess = (recipes) => ({
    type: TYPES.GET_RECIPES_SUCCESS,
    recipes,
});

// Создание рецеата
export const createRecipeRequest = (values) => ({
    type: TYPES.CREATE_RECIPE_REQUEST,
    values,
});

export const createRecipeSuccess = () => ({
    type: TYPES.PATCH_RECIPE_SUCCESS,
});

// Изменение рецепта
export const patchRecipeRequest = (values) => ({
    type: TYPES.PATCH_RECIPE_REQUEST,
    values,
});

export const patchRecipeSuccess = () => ({
    type: TYPES.CREATE_RECIPE_SUCCESS,
});

// Удаление рецепта
export const removeRecipeRequest = (url) => ({
    type: TYPES.REMOVE_RECIPE_REQUEST,
    url,
});

export const removeRecipeSuccess = () => ({
    type: TYPES.REMOVE_RECIPE_SUCCESS,
});

// Ошибка
export const requestFailure = (error) => ({
    type: TYPES.FAILTURE,
    error,
});
