import React from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";

import {
    setMessageboxSuccess,
    setMessageboxFailture,
} from "../../store/messagebox/actions";

import "./input.sass";

export const Input = ({
    title,
    value,
    name,
    type = "text",
    placeholder = "",
    onFieldChange = () => {},
    className,
    titleClass,
    readonly,
}) => {
    const dispatch = useDispatch();

    const onChange = (e) => onFieldChange(name, e.target.value);
    const onContextMenu = (e) => {
        // Проверка на ПК
        if (
            !/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            e.preventDefault();
            navigator.clipboard
                .writeText(e.target.value)
                .then(() =>
                    dispatch(setMessageboxSuccess("Text copied successfully!"))
                )
                .catch(() =>
                    dispatch(setMessageboxFailture("No text copied!"))
                );
        }
    };

    return (
        <div className="input__wrapper">
            <h3 className={classnames("input__title", titleClass)}>{title}</h3>
            <input
                name={name}
                type={type}
                value={value || ""}
                className={classnames("input", className)}
                onChange={onChange}
                onContextMenu={onContextMenu}
                placeholder={placeholder}
                autoComplete="off"
                readOnly={readonly}
            />
        </div>
    );
};
