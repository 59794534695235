import { TYPES } from "../types";

const initialState = {
    isLoading: true,
    isError: false,
    filesList: [],
    files: [],
};

export const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        // Получение всех файлов
        case TYPES.GET_FILES_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.GET_FILES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                filesList: action.files,
            };

        // Получение файлов
        case TYPES.GET_FILE_REQUEST:
            return { ...state, isLoading: true, isError: false };
        case TYPES.GET_FILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                files: action.files,
                date: action.date,
            };

        // Создание файлов
        case TYPES.CREATE_FILE_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.CREATE_FILE_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // Удаление файлов
        case TYPES.REMOVE_FILE_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.REMOVE_FILE_SUCCESS:
            return { ...state, isLoading: false, isError: false };

        // FAILURE
        case TYPES.FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                Error: action.error,
            };

        default:
            return state;
    }
};
