import React from "react";
import { NavLink } from "react-router-dom";

import "./navmenu-item.sass";

export const NavmenuItem = ({ link, text }) => (
    <NavLink
        className="navmenu__item"
        to={link}
        activeClassName="navmenu__item_active"
    >
        <p>{text}</p>
    </NavLink>
);
