import React, { useState, useEffect, useRef, useCallback } from "react";

import { Navmenu } from "../../components/navmenu";
import { User } from "../../components/user";
import { Button } from "../../components/button";

import { WSHOST, wsEvents } from "../../store/endpoints";
import {
    encrypt,
    decrypt,
    arrayBufferToString,
    stringToArrayBuffer,
} from "../../utils/encryption";

import "./chat.sass";

export const Chat = () => {
    // Ключ шифрования
    const [key, setKey] = useState(0);
    // Идентификатор подключения по сокету
    const [connected, setConnected] = useState(false);
    const [users, setUsers] = useState([]);
    // Сокет соединение
    const socket = useRef();

    // Отправка данных по сокету
    const send = useCallback(
        (obj, encKey) => {
            obj = encrypt(JSON.stringify(obj), encKey || key);
            socket.current?.send(stringToArrayBuffer(obj));
        },
        [key]
    );

    // Запрос все пользователей
    const getAllUsers = useCallback(
        (encKey) => {
            send({ event: wsEvents.GET_ALL_USERS }, encKey);
        },
        [send]
    );

    // Удаление всех сообщений в чате
    const clearChat = () => {
        send({ event: wsEvents.REMOVE_ALL_MESSAGES });
    };

    // Обновление ключа шифрования на сервере
    const updateKey = () => {
        send({ event: wsEvents.UPDATE_ENCRYPTION_KEY });
    };

    useEffect(() => {
        // Подключение по сокету
        socket.current = new WebSocket(WSHOST);
        socket.current.binaryType = "arraybuffer";
    }, []);

    useEffect(() => {
        socket.current.onopen = () => {
            setConnected(true);

            // Запрос ключа
            socket.current?.send(
                JSON.stringify({ event: wsEvents.GET_ENCRYPTION_KEY })
            );
        };
        socket.current.onmessage = ({ data }) => {
            if (typeof data === "string") {
                data = JSON.parse(data);
            } else {
                data = decrypt(arrayBufferToString(data), key);
            }

            switch (data?.event) {
                case wsEvents.GET_ENCRYPTION_KEY:
                    setKey(+data.key);
                    return getAllUsers(+data.key);
                case wsEvents.GET_ALL_USERS:
                    return setUsers(data.users);
                case wsEvents.UPDATE_ENCRYPTION_KEY:
                    return setKey(+data.key);

                default:
                    return null;
            }
        };

        // Отключение сокета или ошибка
        socket.current.onclose = () => {
            setConnected(false);
        };
        socket.current.onerror = () => {
            setConnected(false);
        };
    }, [getAllUsers, key]);

    return (
        <div className="page">
            <Navmenu />
            <main className="main">
                <form className="content__wrapper">
                    {connected ? (
                        <>
                            <div className="chat__remote_block">
                                <Button
                                    value="Clear chat"
                                    onClick={clearChat}
                                />
                                <Button
                                    value={`Update encryption key (${key})`}
                                    onClick={updateKey}
                                />
                            </div>
                            <h1 className="chat__title">Create user</h1>
                            <User send={send} />
                            {users.length ? (
                                <h1 className="chat__title">Change users</h1>
                            ) : (
                                <h1 className="chat__title">Users not found</h1>
                            )}
                            {users?.map((user) => (
                                <User user={user} send={send} key={user.id} />
                            ))}
                        </>
                    ) : (
                        <h1 className="chat__title red">
                            Server connection error
                        </h1>
                    )}
                </form>
            </main>
        </div>
    );
};
