import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { Loading } from "../../components/loading";
import { Navmenu } from "../../components/navmenu";
import { Input } from "../../components/input";
import { Textarea } from "../../components/textarea";
import { Date } from "../../components/date";
import { FormButtons } from "../../components/form-buttons";

import { getCodeRequest } from "../../store/codes/actions";
import { patchCodeRequest } from "../../store/codes/actions";
import { removeCodeRequest } from "../../store/codes/actions";

export const Code = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { url } = useParams();
    const { code, isLoading } = useSelector((state) => state.codes);

    const [values, setValues] = useState(code);
    const [date, setDate] = useState();

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    useEffect(() => {
        setValues(code);
    }, [code]);

    useEffect(() => {
        dispatch(getCodeRequest(url));
    }, [dispatch, url]);

    const onSave = () => {
        dispatch(patchCodeRequest(values));
        history.goBack();
    };

    const onDelete = () => {
        dispatch(removeCodeRequest(url));
        history.goBack();
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="page">
                    <Navmenu />
                    <main className="main">
                        <form className="content__wrapper">
                            <Input
                                title="Url"
                                value={values?.url}
                                onFieldChange={onFieldChange}
                                name="url"
                                placeholder="Url"
                            />
                            <Input
                                title="Visits"
                                value={values?.visits}
                                onFieldChange={onFieldChange}
                                name="visits"
                                placeholder="Visits"
                            />
                            <Textarea
                                title="Code"
                                value={values?.code}
                                onFieldChange={onFieldChange}
                                name="code"
                                placeholder="Code"
                            />
                            <Date
                                date={values?.date}
                                values={date}
                                setValues={setDate}
                            />
                            <FormButtons onDelete={onDelete} onSave={onSave} />
                        </form>
                    </main>
                </div>
            )}
        </>
    );
};
