import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Navmenu } from "../../components/navmenu";
import { Input } from "../../components/input";
import { FormButtons } from "../../components/form-buttons";

import { createRequest } from "../../store/users/actions";

export const CreateUser = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [values, setValues] = useState();

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    const onCreate = () => {
        dispatch(createRequest(values));
        history.goBack();
    };

    return (
        <div className="page">
            <Navmenu />
            <main className="main">
                <form className="content__wrapper">
                    <Input
                        title="Login"
                        value={values?.login}
                        onFieldChange={onFieldChange}
                        name="login"
                        placeholder="Login"
                    />
                    <Input
                        title="Password"
                        value={values?.password}
                        onFieldChange={onFieldChange}
                        name="password"
                        placeholder="Password"
                    />
                    <FormButtons onCreate={onCreate} />
                </form>
            </main>
        </div>
    );
};
