import { TYPES } from "../types";

// Получение всех не админских ссылок
export const getLinksRequest = () => ({
    type: TYPES.GET_LINKS_REQUEST,
});

export const getLinksSuccess = (links) => ({
    type: TYPES.GET_LINKS_REQUEST_SUCCESS,
    links,
});

export const getLinksFailure = () => ({
    type: TYPES.GET_LINKS_REQUEST_FAILTURE,
});

// Получение всех админских ссылок
export const getAdminLinksRequest = () => ({
    type: TYPES.GET_ADMIN_LINKS_REQUEST,
});

export const getAdminLinksSuccess = (links) => ({
    type: TYPES.GET_ADMIN_LINKS_REQUEST_SUCCESS,
    links,
});

export const getAdminLinksFailure = () => ({
    type: TYPES.GET_ADMIN_LINKS_REQUEST_FAILTURE,
});

// Получение ссылки
export const getLinkRequest = (shortLink) => ({
    type: TYPES.GET_LINK_REQUEST,
    shortLink,
});

export const getLinkSuccess = (original_link) => ({
    type: TYPES.GET_LINK_REQUEST_SUCCESS,
    original_link,
});

// Изменение ссылки
export const createLinkRequest = (values) => ({
    type: TYPES.CREATE_LINK_REQUEST,
    values,
});

export const createLinkSuccess = () => ({
    type: TYPES.CREATE_LINK_REQUEST_SUCCESS,
});

// Изменение ссылки
export const patchLinkRequest = (values) => ({
    type: TYPES.PATCH_LINK_REQUEST,
    values,
});

export const patchLinkSuccess = () => ({
    type: TYPES.PATCH_LINK_REQUEST_SUCCESS,
});

// Удаление ссылки
export const removeLinkRequest = (id) => ({
    type: TYPES.REMOVE_LINK_REQUEST,
    id,
});

export const removeLinkSuccess = () => ({
    type: TYPES.REMOVE_LINK_REQUEST_SUCCESS,
});

// Ошибка
export const requestFailure = (error) => ({
    type: TYPES.FAILTURE,
    error,
});
