import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { FileLoading } from "../../components/file-loading";
import { Navmenu } from "../../components/navmenu";
import { Input } from "../../components/input";
import { Choose } from "../../components/choose";
import { FormButtons } from "../../components/form-buttons";

import { createFileRequest } from "../../store/files/actions";

export const CreateFile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [values, setValues] = useState();
    const [formData, setFormData] = useState(null);
    const [fileLoading, setFileLoading] = useState(null);

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    const onCreate = () => {
        const date = new window.Date();
        dispatch(
            createFileRequest(
                {
                    ...values,
                    date: `${date.getFullYear()}-${
                        date.getMonth() + 1
                    }-${date.getDate()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                },
                formData,
                setFileLoading
            )
        );
        history.goBack();
    };

    return (
        <>
            {fileLoading?.isFileLoading && (
                <FileLoading fileLoading={fileLoading} />
            )}
            <div className="page">
                <Navmenu />
                <main className="main">
                    <form className="content__wrapper">
                        <Input
                            title="Url"
                            value={values?.url}
                            onFieldChange={onFieldChange}
                            name="url"
                            placeholder="Url"
                        />
                        <Choose setValues={setFormData} />
                        <FormButtons onCreate={onCreate} />
                    </form>
                </main>
            </div>
        </>
    );
};
