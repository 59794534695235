import { put, call, fork, takeLatest } from "redux-saga/effects";

import { ENDPOINTS } from "../endpoints";
import { TYPES } from "../types";
import {
    createLinkSuccess,
    getLinksSuccess,
    getAdminLinksSuccess,
    getAdminLinksFailure,
    getLinkSuccess,
    patchLinkSuccess,
    removeLinkSuccess,
    getLinksFailure,
    requestFailure,
} from "./actions";

import { axiosInstance } from "../../utils/axios-instance";

// Получение всех не админских ссылок
function* getLinks() {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.LINKS_ENDPOINT
        );
        yield put(getLinksSuccess(data));
    } catch (err) {
        yield put(getLinksFailure(err));
    }
}

// Получение всех админских ссылок
function* getAdminLinks() {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.ADMIN_LINKS_ENDPOINT
        );
        yield put(getAdminLinksSuccess(data));
    } catch (err) {
        yield put(getAdminLinksFailure(err));
    }
}

// Получение одной ссылки
function* getLink({ shortLink }) {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.LINK_ENDPOINT,
            { params: { shortLink } }
        );
        yield put(getLinkSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// CREATE запрос
function* createLink({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.post,
            ENDPOINTS.LINK_ENDPOINT,
            values
        );
        yield put(createLinkSuccess(data));
        yield fork(getLinks);
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// PATCH запрос
function* patchLink({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.patch,
            ENDPOINTS.LINK_ENDPOINT,
            values
        );
        yield fork(getLinks);
        yield put(patchLinkSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// DELETE запрос
function* removeLink({ id }) {
    try {
        const { data } = yield call(
            axiosInstance.delete,
            ENDPOINTS.LINK_ENDPOINT,
            { params: { id } }
        );
        yield fork(getLinks);
        yield put(removeLinkSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

export default function* linksSaga() {
    yield takeLatest(TYPES.GET_LINK_REQUEST, getLink);
    yield takeLatest(TYPES.GET_LINKS_REQUEST, getLinks);
    yield takeLatest(TYPES.GET_ADMIN_LINKS_REQUEST, getAdminLinks);
    yield takeLatest(TYPES.CREATE_LINK_REQUEST, createLink);
    yield takeLatest(TYPES.PATCH_LINK_REQUEST, patchLink);
    yield takeLatest(TYPES.REMOVE_LINK_REQUEST, removeLink);
}
