import { put, call, fork, takeLatest } from "redux-saga/effects";

import { ENDPOINTS } from "../endpoints";
import { TYPES } from "../types";
import {
    getCodeSuccess,
    getCodesSuccess,
    createCodeSuccess,
    patchCodeSuccess,
    removeCodeSuccess,
    sendSQLSuccess,
    requestFailure,
} from "./actions";

import { axiosInstance } from "../../utils/axios-instance";

// Получение всех кода
function* getCodes() {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.CODES_ENDPOINT
        );
        yield put(getCodesSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Получение кода
function* getCode({ url }) {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.CODE_ENDPOINT,
            { params: { url } }
        );
        yield put(getCodeSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Создание кода
function* createCode({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.post,
            ENDPOINTS.CODE_ENDPOINT,
            values
        );
        yield put(createCodeSuccess(data));
        yield fork(getCodes);
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Создание кода
function* patchCode({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.patch,
            ENDPOINTS.CODE_ENDPOINT,
            values
        );
        yield put(patchCodeSuccess(data));
        yield fork(getCodes);
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Удаление кода
function* removeCode({ url }) {
    try {
        const { data } = yield call(
            axiosInstance.delete,
            ENDPOINTS.CODE_ENDPOINT,
            { params: { url } }
        );
        yield fork(getCodes);
        yield put(removeCodeSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// SQL запрос
function* sendSQL({ code }) {
    try {
        const { data } = yield call(
            axiosInstance.post,
            ENDPOINTS.SQL_ENDPOINT,
            { code }
        );
        yield put(sendSQLSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

export default function* filesSaga() {
    yield takeLatest(TYPES.GET_CODE_REQUEST, getCode);
    yield takeLatest(TYPES.GET_CODES_REQUEST, getCodes);
    yield takeLatest(TYPES.CREATE_CODE_REQUEST, createCode);
    yield takeLatest(TYPES.PATCH_CODE_REQUEST, patchCode);
    yield takeLatest(TYPES.REMOVE_CODE_REQUEST, removeCode);
    yield takeLatest(TYPES.SEND_SQL_REQUEST, sendSQL);
}
