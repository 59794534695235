import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Input } from "../../components/input";
import { Button } from "../../components/button";

import { loginRequest } from "../../store/login/actions";

import "./login.sass";

export const Login = () => {
    const dispatch = useDispatch();

    const [values, setValues] = useState();

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    const login = () => dispatch(loginRequest(values));

    return (
        <form className="login">
            <Input
                title="Login"
                value={values?.login}
                onFieldChange={onFieldChange}
                name="login"
                type="password"
                placeholder="Login"
            />
            <Input
                title="Password"
                value={values?.password}
                onFieldChange={onFieldChange}
                name="password"
                type="password"
                placeholder="Password"
            />
            <div className="login__btn_wrapper">
                <Button value="Log in" onClick={login} />
            </div>
        </form>
    );
};
