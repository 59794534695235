import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

import { Loading } from "../../components/loading";
import { Navmenu } from "../../components/navmenu";
import { RecordsPanel } from "../../components/records-panel";
import { Record } from "../../components/record";

import { getLinksRequest } from "../../store/links/actions";
import { filterRecords } from "../../utils/filter-records";

export const Links = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const { links, isLoading } = useSelector((state) => state.links);

    useEffect(() => {
        dispatch(getLinksRequest());
    }, [dispatch]);

    const filteredRecords = filterRecords(links, searchValue, [
        "original_link",
        "short_link",
    ]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="page">
                    <Navmenu />
                    <main className="main">
                        <RecordsPanel
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            link="/link/create"
                        />
                        {filteredRecords ? (
                            filteredRecords.map((link) => (
                                <Record
                                    id={link.id}
                                    title={link.short_link}
                                    subtitle={link.original_link}
                                    onClick={() => (
                                        <Redirect
                                            to={`/link/${link.short_link}`}
                                        />
                                    )}
                                    link="link"
                                    key={link.id}
                                />
                            ))
                        ) : (
                            <p className="not__found">Records not found...</p>
                        )}
                    </main>
                </div>
            )}
        </>
    );
};
