import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import classNames from "classnames";
import Cookies from "js-cookie";

import { Messagebox } from "../messagebox";
import { Loading } from "../loading";

import { Login } from "../../pages/login";
import { Links } from "../../pages/links";
import { Link } from "../../pages/link";
import { AdminLinks } from "../../pages/admin-links";
import { CreateLink } from "../../pages/create-link";

import { Files } from "../../pages/files";
import { CreateFile } from "../../pages/create-file";
import { File } from "../../pages/file";

import { Codes } from "../../pages/codes";
import { Code } from "../../pages/code";
import { CreateCode } from "../../pages/create-code";

import { Recipes } from "../../pages/recipes";
import { Recipe } from "../../pages/recipe";
import { CreateRecipe } from "../../pages/create-recipe";

import { Users } from "../../pages/users";
import { User } from "../../pages/user";
import { CreateUser } from "../../pages/create-user";

import { Chat } from "../../pages/chat";

import { SQL } from "../../pages/sql";

import { checkTokenRequest } from "../../store/login/actions";

import { countSSL } from "../../utils/count-ssl";

import "./app.sass";

export const App = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, isLoading } = useSelector((state) => state.login);

    useEffect(() => {
        const token = Cookies.get("token");
        dispatch(checkTokenRequest(token));
    }, [dispatch]);

    return (
        <div className="container">
            <Messagebox />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {isAuthenticated ? (
                        <>
                            <div
                                className={classNames("ssl", {
                                    red: countSSL() <= 7,
                                })}
                            >{`${countSSL()} days`}</div>
                            <Switch>
                                <Route path="/links" component={Links} />
                                <Route
                                    path="/admin-links"
                                    component={AdminLinks}
                                />
                                <Route
                                    exact
                                    path="/link/create"
                                    component={CreateLink}
                                />
                                <Route
                                    exact
                                    path="/link/:short"
                                    component={Link}
                                />

                                <Route exact path="/files" component={Files} />
                                <Route
                                    exact
                                    path="/file/create"
                                    component={CreateFile}
                                />
                                <Route
                                    exact
                                    path="/file/:url"
                                    component={File}
                                />

                                <Route exact path="/codes" component={Codes} />
                                <Route
                                    exact
                                    path="/code/create"
                                    component={CreateCode}
                                />
                                <Route
                                    exact
                                    path="/code/:url"
                                    component={Code}
                                />

                                <Route
                                    exact
                                    path="/recipes"
                                    component={Recipes}
                                />
                                <Route
                                    exact
                                    path="/recipe/create"
                                    component={CreateRecipe}
                                />
                                <Route
                                    exact
                                    path="/recipe/:url"
                                    component={Recipe}
                                />

                                <Route exact path="/users" component={Users} />
                                <Route
                                    exact
                                    path="/user/create"
                                    component={CreateUser}
                                />
                                <Route
                                    exact
                                    path="/user/:login"
                                    component={User}
                                />

                                <Route exact path="/chat" component={Chat} />

                                <Route exact path="/sql" component={SQL} />

                                <Redirect to="/links" />
                            </Switch>
                        </>
                    ) : (
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Redirect to="/" />
                        </Switch>
                    )}
                </>
            )}
        </div>
    );
};
