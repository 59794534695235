import { TYPES } from "../types";

// Получение кода
export const getCodeRequest = (url) => ({
    type: TYPES.GET_CODE_REQUEST,
    url,
});

export const getCodeSuccess = (values) => ({
    type: TYPES.GET_CODE_SUCCESS,
    values,
});

// Получение всех кодов
export const getCodesRequest = () => ({
    type: TYPES.GET_CODES_REQUEST,
});

export const getCodesSuccess = (codes) => ({
    type: TYPES.GET_CODES_SUCCESS,
    codes,
});

// Создание кода
export const createCodeRequest = (values) => ({
    type: TYPES.CREATE_CODE_REQUEST,
    values,
});

export const createCodeSuccess = () => ({
    type: TYPES.PATCH_CODE_SUCCESS,
});

// Изменение кода
export const patchCodeRequest = (values) => ({
    type: TYPES.PATCH_CODE_REQUEST,
    values,
});

export const patchCodeSuccess = () => ({
    type: TYPES.CREATE_CODE_SUCCESS,
});

// Удаление кода
export const removeCodeRequest = (url) => ({
    type: TYPES.REMOVE_CODE_REQUEST,
    url,
});

export const removeCodeSuccess = () => ({
    type: TYPES.REMOVE_CODE_SUCCESS,
});

// SQL запрос

export const sendSQLRequest = ({ code }) => ({
    type: TYPES.SEND_SQL_REQUEST,
    code,
});

export const sendSQLSuccess = () => ({
    type: TYPES.SEND_SQL_SUCCESS,
});

// Ошибка
export const requestFailure = (error) => ({
    type: TYPES.FAILTURE,
    error,
});
