export const TYPES = {
    // Get
    GET_LINK_REQUEST: "GET_LINK_REQUEST",
    GET_LINK_REQUEST_SUCCESS: "GET_LINK_REQUEST_SUCCESS",
    GET_LINKS_REQUEST: "GET_LINKS_REQUEST",
    GET_LINKS_REQUEST_SUCCESS: "GET_LINKS_REQUEST_SUCCESS",
    GET_LINKS_REQUEST_FAILTURE: "GET_LINKS_REQUEST_FAILTURE",
    GET_ADMIN_LINKS_REQUEST: "GET_ADMIN_LINKS_REQUEST",
    GET_ADMIN_LINKS_REQUEST_SUCCESS: "GET_ADMIN_LINKS_REQUEST_SUCCESS",
    GET_ADMIN_LINKS_REQUEST_FAILTURE: "GET_ADMIN_LINKS_REQUEST_FAILTURE",

    // CREATE
    CREATE_LINK_REQUEST: "CREATE_LINK_REQUEST",
    CREATE_LINK_REQUEST_SUCCESS: "CREATE_LINK_REQUEST_SUCCESS",
    // PATCH
    PATCH_LINK_REQUEST: "PATCH_LINK_REQUEST",
    PATCH_LINK_REQUEST_SUCCESS: "PATCH_LINK_REQUEST_SUCCESS",
    // DELETE
    REMOVE_LINK_REQUEST: "REMOVE_LINK_REQUEST",
    REMOVE_LINK_REQUEST_SUCCESS: "REMOVE_LINK_REQUEST_SUCCESS",

    // Failture
    FAILTURE: "FAILTURE",

    // Messagebox
    SET_MESSAGEBOX_SUCCESS: "SET_MESSAGEBOX_SUCCESS",
    SET_MESSAGEBOX_ERROR: "SET_MESSAGEBOX_ERROR",
    SET_MESSAGEBOX_DEFAULT: "SET_MESSAGEBOX_DEFAULT",

    // Login
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILTURE: "LOGIN_FAILTURE",
    CHECK_TOKEN_REQUEST: "CHECK_TOKEN_REQUEST",
    CHECK_TOKEN_SUCCESS: "CHECK_TOKEN_SUCCESS",
    CHECK_TOKEN_FAILTURE: "CHECK_TOKEN_FAILTURE",

    // Users
    GET_USERS_REQUEST: "GET_USERS_REQUEST",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USER_REQUEST: "GET_USER_REQUEST",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    PATCH_USER_REQUEST: "PATCH_USER_REQUEST",
    PATCH_USER_SUCCESS: "PATCH_USER_SUCCESS",
    REMOVE_USER_REQUEST: "REMOVE_USER_REQUEST",
    REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",

    // Files
    GET_FILES_REQUEST: "GET_FILES_REQUEST",
    GET_FILES_SUCCESS: "GET_FILES_SUCCESS",
    GET_FILE_REQUEST: "GET_FILE_REQUEST",
    GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
    CREATE_FILE_REQUEST: "CREATE_FILE_REQUEST",
    CREATE_FILE_SUCCESS: "CREATE_FILE_SUCCESS",
    REMOVE_FILE_REQUEST: "REMOVE_FILE_REQUEST",
    REMOVE_FILE_SUCCESS: "REMOVE_FILES_SUCCESS",

    // Codes
    GET_CODES_REQUEST: "GET_CODES_REQUEST",
    GET_CODES_SUCCESS: "GET_CODES_SUCCESS",
    GET_CODE_REQUEST: "GET_CODE_REQUEST",
    GET_CODE_SUCCESS: "GET_CODE_SUCCESS",
    CREATE_CODE_REQUEST: "CREATE_CODE_REQUEST",
    CREATE_CODE_SUCCESS: "CREATE_CODE_SUCCESS",
    PATCH_CODE_REQUEST: "PATCH_CODE_REQUEST",
    PATCH_CODE_SUCCESS: "PATCH_CODE_SUCCESS",
    REMOVE_CODE_REQUEST: "REMOVE_CODE_REQUEST",
    REMOVE_CODE_SUCCESS: "REMOVE_CODE_SUCCESS",
    SEND_SQL_REQUEST: "SEND_SQL_REQUEST",
    SEND_SQL_SUCCESS: "SEND_SQL_SUCCESS",

    // Recipes
    GET_RECIPES_REQUEST: "GET_RECIPES_REQUEST",
    GET_RECIPES_SUCCESS: "GET_RECIPES_SUCCESS",
    GET_RECIPE_REQUEST: "GET_RECIPE_REQUEST",
    GET_RECIPE_SUCCESS: "GET_RECIPE_SUCCESS",
    CREATE_RECIPE_REQUEST: "CREATE_RECIPE_REQUEST",
    CREATE_RECIPE_SUCCESS: "CREATE_RECIPE_SUCCESS",
    PATCH_RECIPE_REQUEST: "PATCH_RECIPE_REQUEST",
    PATCH_RECIPE_SUCCESS: "PATCH_RECIPE_SUCCESS",
    REMOVE_RECIPE_REQUEST: "REMOVE_RECIPE_REQUEST",
    REMOVE_RECIPE_SUCCESS: "REMOVE_RECIPE_SUCCESS",
};
