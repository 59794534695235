import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loading } from "../../components/loading";
import { Navmenu } from "../../components/navmenu";
import { RecordsPanel } from "../../components/records-panel";
import { Record } from "../../components/record";

import { getUsersRequest } from "../../store/users/actions";
import { filterRecords } from "../../utils/filter-records";

export const Users = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        dispatch(getUsersRequest());
    }, [dispatch]);

    const { usersList, isLoading } = useSelector((state) => state.users);

    const filteredRecords = filterRecords(usersList, searchValue, ["login"]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="page">
                    <Navmenu />
                    <main className="main">
                        <RecordsPanel
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            link="/user/create"
                        />
                        {filteredRecords ? (
                            filteredRecords.map((user, idx) => (
                                <Record
                                    title={user.login}
                                    link="user"
                                    key={idx.toString()}
                                />
                            ))
                        ) : (
                            <p className="not__found">Records not found...</p>
                        )}
                    </main>
                </div>
            )}
        </>
    );
};
