import { TYPES } from "../types";

const initialState = {
    isLoading: true,
    isError: false,
    isAuthenticated: false,
    id: "",
    token: "",
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        // Получение всех ссылок
        case TYPES.LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };

        case TYPES.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isAuthenticated: true,
                id: action.userId,
                token: action.token,
            };

        // FAILURE
        case TYPES.LOGIN_FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                Error: action.error,
                isAuthenticated: false,
                id: "",
                token: "",
            };

        // Проверка JWT токена
        case TYPES.CHECK_TOKEN_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };

        case TYPES.CHECK_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isAuthenticated: action.isValid,
            };

        case TYPES.CHECK_TOKEN_FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                isAuthenticated: false,
            };

        default:
            return state;
    }
};
