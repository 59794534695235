import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { Loading } from "../../components/loading";
import { Navmenu } from "../../components/navmenu";
import { Input } from "../../components/input";
import { FormButtons } from "../../components/form-buttons";

import {
    getUserRequest,
    patchUserRequest,
    removeUserRequest,
} from "../../store/users/actions";

export const User = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { login } = useParams();
    const { user, isLoading } = useSelector((state) => state.users);

    const [values, setValues] = useState();

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    useEffect(() => {
        dispatch(getUserRequest(login));
    }, [dispatch, login]);

    useEffect(() => {
        if (user) setValues(user);
    }, [user]);

    const onSave = () => {
        dispatch(patchUserRequest(values));
        history.goBack();
    };

    const onDelete = () => {
        dispatch(removeUserRequest(values.login));
        history.goBack();
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="page">
                    <Navmenu />
                    <main className="main">
                        <form className="content__wrapper">
                            <Input
                                title="Login"
                                value={values?.login}
                                onFieldChange={onFieldChange}
                                name="login"
                                placeholder="Login"
                            />
                            <Input
                                title="Password"
                                value={values?.password}
                                onFieldChange={onFieldChange}
                                name="password"
                                placeholder="Password"
                            />
                            <FormButtons onSave={onSave} onDelete={onDelete} />
                        </form>
                    </main>
                </div>
            )}
        </>
    );
};
