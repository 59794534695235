import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { Loading } from "../../components/loading";
import { Navmenu } from "../../components/navmenu";
import { Input } from "../../components/input";
import { Choose } from "../../components/choose";
import { FormButtons } from "../../components/form-buttons";

import { getFileRequest } from "../../store/files/actions";
import { removeFileRequest } from "../../store/files/actions";

export const File = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { url } = useParams();
    const { files, date, isLoading } = useSelector((state) => state.files);

    const [values, setValues] = useState(files);

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    useEffect(() => {
        dispatch(getFileRequest(url));
    }, [dispatch, url]);

    useEffect(() => {
        if (files) setValues({ url, files });
    }, [files, url]);

    const onDelete = () => {
        dispatch(removeFileRequest(url));
        history.goBack();
    };

    const convertDate = ({ date }) =>
        date ? date?.split("T")[0].replaceAll("-", ".") : "unknown";

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="page">
                    <Navmenu />
                    <main className="main">
                        <form className="content__wrapper">
                            <Input
                                title="Url"
                                value={values?.url}
                                onFieldChange={onFieldChange}
                                name="url"
                                placeholder="Url"
                            />
                            <Choose values={values.files} />
                            <strong>
                                Date of creation {convertDate(date || "")}
                            </strong>
                            <FormButtons onDelete={onDelete} />
                        </form>
                    </main>
                </div>
            )}
        </>
    );
};
