import React from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";

import {
    setMessageboxSuccess,
    setMessageboxFailture,
} from "../../store/messagebox/actions";

import "./textarea.sass";

export const Textarea = ({
    title,
    value,
    name,
    type = "text",
    placeholder = "",
    onFieldChange = () => {},
    className,
    titleClass,
    readonly,
}) => {
    const dispatch = useDispatch();

    const onChange = (e) => onFieldChange(name, e.target.value);
    const onContextMenu = (e) => {
        e.preventDefault();
        navigator.clipboard
            .writeText(e.target.value)
            .then(() =>
                dispatch(setMessageboxSuccess("Text copied successfully!"))
            )
            .catch(() => dispatch(setMessageboxFailture("No text copied!")));
    };

    return (
        <div className="textarea__wrapper">
            <h3 className={classnames("textarea__title", titleClass)}>
                {title}
            </h3>
            <textarea
                name={name}
                type={type}
                value={value || ""}
                className={classnames("textarea", className)}
                onChange={onChange}
                onContextMenu={onContextMenu}
                placeholder={placeholder}
                autoComplete="off"
                readOnly={readonly}
            />
        </div>
    );
};
