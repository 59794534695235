import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import "./button.sass";

export const Button = ({
    value,
    onClick = () => {},
    className,
    isLink = false,
    link,
}) => (
    <>
        {isLink ? (
            <Link to={link} className={classNames("button", "button__link")}>
                {value}
            </Link>
        ) : (
            <button
                onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }}
                className={classNames("button", className)}
            >
                {value}
            </button>
        )}
    </>
);
