export const filterRecords = (records, searchValue, keys) => {
    // Создание конечного массива с результами поиска
    let filteredArray = [];
    if (searchValue) {
        records.forEach((record) => {
            let isContain = false;
            // Мапим массив записей и ищем там совпадающие с пропсами ключи, если нашли, сравниваем их текст и поисковым текстом, если он есть, то isContains = true
            Object.keys(record).map(
                (key) =>
                    keys.includes(key) &&
                    record[key]
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) &&
                    (isContain = true)
            );
            // Если текст найден в объекте, значит добавляем эту запись в итоговый массив
            isContain && filteredArray.push(record);
        });
        // если по запросу найдено 0 записей, возвращаем null
        if (!filteredArray.length) return null;
    } else return records;

    // Возвращаем массив найдейнных записйе
    return filteredArray;
};
