import { TYPES } from "../types";

// Получение пользователей
export const getUsersRequest = () => ({
    type: TYPES.GET_USERS_REQUEST,
});

export const getUsersSuccess = (users) => ({
    type: TYPES.GET_USERS_SUCCESS,
    users,
});

// Получение пользователя
export const getUserRequest = (login) => ({
    type: TYPES.GET_USER_REQUEST,
    login,
});

export const getUserSuccess = (login) => ({
    type: TYPES.GET_USER_SUCCESS,
    login,
});

// Создание пользователя
export const createRequest = (values) => ({
    type: TYPES.CREATE_USER_REQUEST,
    values,
});

export const createSuccess = () => ({
    type: TYPES.CREATE_USER_SUCCESS,
});

// Изменение пользователя
export const patchUserRequest = (values) => ({
    type: TYPES.PATCH_USER_REQUEST,
    values,
});

export const patchUserSuccess = () => ({
    type: TYPES.PATCH_USER_SUCCESS,
});

// Удаление пользователя
export const removeUserRequest = (login) => ({
    type: TYPES.REMOVE_USER_REQUEST,
    login,
});

export const removeUserSuccess = () => ({
    type: TYPES.REMOVE_USER_SUCCESS,
});

// Ошибка
export const requestFailure = (error) => ({
    type: TYPES.FAILTURE,
    error,
});
