import { TYPES } from "../types";

const initialState = {
    isLoading: true,
    isError: false,
    links: [],
    adminLinks: [],
    link: {},
    error: "",
};

export const linksReducer = (state = initialState, action) => {
    switch (action.type) {
        // Получение всех не админских  ссылок
        case TYPES.GET_LINKS_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };

        case TYPES.GET_LINKS_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                links: action.links,
            };

        case TYPES.GET_LINKS_REQUEST_FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: false,
                links: [],
            };

        // Получение всех админских ссылок
        case TYPES.GET_ADMIN_LINKS_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };

        case TYPES.GET_ADMIN_LINKS_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                adminLinks: action.links,
            };

        case TYPES.GET_ADMIN_LINKS_REQUEST_FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: false,
                adminLinks: [],
            };

        // Получение ссылки
        case TYPES.GET_LINK_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case TYPES.GET_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                link: action.original_link,
            };

        // Изменение ссылки
        case TYPES.PATCH_LINK_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };

        case TYPES.PATCH_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
            };

        // Удаление ссылки
        case TYPES.REMOVE_LINK_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };

        case TYPES.REMOVE_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
            };

        // FAILURE
        case TYPES.FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                Error: action.error,
            };

        default:
            return state;
    }
};
