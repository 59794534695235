import { put, call, fork, takeLatest } from "redux-saga/effects";

import { ENDPOINTS } from "../endpoints";
import { TYPES } from "../types";
import {
    getUserSuccess,
    getUsersSuccess,
    createSuccess,
    patchUserSuccess,
    removeUserSuccess,
    requestFailure,
} from "./actions";

import { axiosInstance } from "../../utils/axios-instance";

// Получение всех пользователей
function* getUsers() {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.USERS_ENDPOINT
        );
        yield put(getUsersSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Получение пользователя
function* getUser({ login }) {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.USER_ENDPOINT,
            { params: { login } }
        );
        yield put(getUserSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Регистрация пользователя
function* createUser({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.post,
            ENDPOINTS.USER_ENDPOINT,
            values
        );
        yield put(createSuccess(data));
        yield fork(getUsers);
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// // Изменене пользователя
function* patchUser({ values }) {
    try {
        const { data } = yield call(
            axiosInstance.patch,
            ENDPOINTS.USER_ENDPOINT,
            values
        );
        yield fork(getUsers);
        yield put(patchUserSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// // Удаление пользователя
function* removeUser({ login }) {
    try {
        const { data } = yield call(
            axiosInstance.delete,
            ENDPOINTS.USER_ENDPOINT,
            { params: { login } }
        );
        yield fork(getUsers);
        yield put(removeUserSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

export default function* usersSaga() {
    yield takeLatest(TYPES.GET_USER_REQUEST, getUser);
    yield takeLatest(TYPES.GET_USERS_REQUEST, getUsers);
    yield takeLatest(TYPES.CREATE_USER_REQUEST, createUser);
    yield takeLatest(TYPES.PATCH_USER_REQUEST, patchUser);
    yield takeLatest(TYPES.REMOVE_USER_REQUEST, removeUser);
}
