import React, { useEffect, useState } from "react";

import { Input } from "../input";
import { FormButtons } from "../form-buttons";

import { wsEvents } from "../../store/endpoints";

export const User = ({ user, send }) => {
    const [values, setValues] = useState();

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    useEffect(() => {
        setValues(user);
    }, [user]);

    // Создание пользователя
    const onCreate = () => {
        send({ event: wsEvents.CREATE_USER, values });
        send({ event: wsEvents.GET_ALL_USERS });
        // Очитка инпутов для создания нового пользователя
        setValues({});
    };

    // Изменение существующего ползователя
    const onSave = () => send({ event: wsEvents.PATCH_USER, values });

    // Удаление пользователя чата
    const onDelete = () => {
        send({ event: wsEvents.REMOVE_USER, id: values?.id });
        send({ event: wsEvents.GET_ALL_USERS });
    };

    return (
        <div>
            <Input
                title="Username"
                value={values?.username}
                onFieldChange={onFieldChange}
                name="username"
                placeholder="Username"
            />
            <Input
                title="Avatar"
                value={values?.avatar}
                onFieldChange={onFieldChange}
                name="avatar"
                placeholder="Avatar"
            />
            <Input
                title="Invite"
                value={values?.invite}
                onFieldChange={onFieldChange}
                name="invite"
                placeholder="Invite"
            />
            {user ? (
                <FormButtons onSave={onSave} onDelete={onDelete} />
            ) : (
                <FormButtons onCreate={onCreate} />
            )}
        </div>
    );
};
