import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Button } from "../button";
import { HOST } from "../../store/endpoints";

import { setMessageboxSuccess } from "../../store/messagebox/actions";

import "./choose.sass";

export const Choose = ({ values, setValues }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState(values);
    const [drag, setDrag] = useState(false);
    const input = useRef(null);
    const formFiles = new FormData();

    const addFiles = (newFiles) => {
        let filesArray = [];
        for (let i = 0; i < newFiles.length; i++) {
            formFiles.append("files", newFiles[i]);
            filesArray.push(newFiles[i].name);
        }
        setValues(formFiles);
        setFiles(filesArray);
    };

    useEffect(() => {
        setFiles(values);
    }, [values]);

    // Drag and drop
    const dragHandler = (e) => {
        e.preventDefault();
        setDrag(true);
    };
    const onDragLeave = (e) => {
        e.preventDefault();
        setDrag(false);
    };
    const onDrop = (e) => {
        e.preventDefault();
        addFiles([...e.dataTransfer.files]);
        setDrag(false);
    };

    window.addEventListener("dragstart", dragHandler);
    window.addEventListener("dragover", dragHandler);
    window.addEventListener("drop", onDrop);

    const onCopyLink = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(`https://ryletd.ru/file/${files[0].url}`);
        dispatch(setMessageboxSuccess("Link copied successfully!"));
    };

    return (
        <div className="choose">
            {drag && (
                <div
                    className="drag__drop"
                    onDragStart={dragHandler}
                    onDragLeave={onDragLeave}
                    onDragOver={dragHandler}
                    onDrop={() => {
                        setDrag(false);
                        addFiles(input.current.files);
                    }}
                >
                    Drop your files...
                </div>
            )}
            <div className="choose__list">
                {files &&
                    files.map((item, idx) =>
                        typeof item === "string" ? (
                            <p
                                key={idx.toString()}
                                className="choose__file_title"
                            >
                                {item}
                            </p>
                        ) : (
                            <div key={idx.toString()}>
                                <a
                                    key={idx.toString()}
                                    className="choose__file_title"
                                    href={`${HOST}\\files\\${item.url}\\${item.name}`}
                                    onContextMenu={onCopyLink}
                                >
                                    {item.name}
                                </a>
                                <div className="choose__file_info">
                                    <span className="choose__file_heading">
                                        Downloads:{" "}
                                    </span>
                                    <span className="choose__file_value">
                                        {item.downloads}
                                    </span>
                                </div>
                                <div className="choose__file_info">
                                    <span className="choose__file_heading">
                                        Size:{" "}
                                    </span>
                                    <span className="choose__file_value">
                                        {item.size.KB > 1
                                            ? item.size.MB > 1
                                                ? item.size.GB > 1
                                                    ? item.size.TB > 1
                                                        ? `${item.size.TB.toFixed(
                                                              2
                                                          )} tb`
                                                        : `${item.size.GB.toFixed(
                                                              2
                                                          )} gb`
                                                    : `${item.size.MB.toFixed(
                                                          2
                                                      )} mb`
                                                : `${item.size.KB.toFixed(
                                                      2
                                                  )} kb`
                                            : `${item.size.B} byte`}
                                    </span>
                                </div>
                            </div>
                        )
                    )}
            </div>
            {setValues && (
                <>
                    <Button
                        value="Add files"
                        onClick={() => input.current.click()}
                    />
                    <input
                        name="files"
                        type="file"
                        className="choose__file"
                        ref={input}
                        onChange={(e) => addFiles(e.target.files)}
                        multiple
                    />
                </>
            )}
        </div>
    );
};

// https://www.youtube.com/watch?v=7F1w63ZfgDY&ab_channel=UlbiTV
