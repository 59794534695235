import React from "react";

import "./options.sass";

export const Options = ({ title, name, isActive, setIsActive }) => (
    <>
        <h3 className="options__title">{title}</h3>
        {isActive ? (
            <div className="options__wrapper">
                <div className="options__btn acive">YES</div>
                <div
                    className="options__btn"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsActive(name, false);
                    }}
                >
                    NO
                </div>
            </div>
        ) : (
            <div className="options__wrapper">
                <div
                    className="options__btn"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsActive(name, true);
                    }}
                >
                    YES
                </div>
                <div className="options__btn acive">NO</div>
            </div>
        )}
    </>
);
