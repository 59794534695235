export const HOST = "https://api.ryletd.ru";
export const WSHOST = "wss://ws.ryletd.ru";
// export const HOST = "http://localhost:5500";
// export const WSHOST = "ws://localhost:5501";

export const ENDPOINTS = {
    LINK_ENDPOINT: "/link",
    LINKS_ENDPOINT: "/links",
    ADMIN_LINKS_ENDPOINT: "/admin-links",

    // Endpoints для авторизации
    LOGIN_ENDPOINT: "/login",
    USERS_ENDPOINT: "/users",
    USER_ENDPOINT: "/user",
    CHECK_TOKEN_ENDPOINT: "/check",

    // Endpoints для файлов
    FILES_ENDPOINT: "/files",
    FILE_ENDPOINT: "/file",

    // Endpoints для кодов
    CODES_ENDPOINT: "/codes",
    CODE_ENDPOINT: "/code",

    // Endpoints для рецептов
    RECIPES_ENDPOINT: "/recipes",
    RECIPE_ENDPOINT: "/recipe",

    // Endpoints для SQL запросов
    SQL_ENDPOINT: "/sql",
};

export const wsEvents = {
    GET_ENCRYPTION_KEY: "GET_ENCRYPTION_KEY",
    UPDATE_ENCRYPTION_KEY: "UPDATE_ENCRYPTION_KEY",
    GET_ALL_USERS: "GET_ALL_USERS",
    CREATE_USER: "CREATE_USER",
    PATCH_USER: "PATCH_USER",
    REMOVE_USER: "REMOVE_USER",
    REMOVE_ALL_MESSAGES: "REMOVE_ALL_MESSAGES",
};
