import { put, call, fork, takeLatest } from "redux-saga/effects";

import { ENDPOINTS } from "../endpoints";
import { TYPES } from "../types";
import {
    getFileSuccess,
    getFilesSuccess,
    createFileSuccess,
    removeFileSuccess,
    requestFailure,
} from "./actions";

import { axiosInstance } from "../../utils/axios-instance";

// Получение всех файлов
function* getFiles() {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.FILES_ENDPOINT
        );
        yield put(getFilesSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Получение файла
function* getFile({ url }) {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.FILE_ENDPOINT,
            { params: { url } }
        );
        yield put(getFileSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Создание файла
function* createFile({ values, formData }) {
    try {
        const { data } = yield call(
            axiosInstance.post,
            ENDPOINTS.FILE_ENDPOINT,
            formData,
            {
                params: values,
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (e) => {
                    const filesSize = e.lengthComputable
                        ? e.total
                        : e.target.getResponseHeader("content-length") ||
                          e.target.getResponseHeader(
                              "x-decompressed-content-length"
                          );
                    let progress = 0;
                    if (filesSize) {
                        progress = Math.round((e.loaded * 100) / filesSize);
                    }
                    console.log(filesSize, progress);
                },
            }
        );
        yield put(createFileSuccess(data));
        yield fork(getFiles);
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Удаление файла
function* removeFile({ url }) {
    try {
        const { data } = yield call(
            axiosInstance.delete,
            ENDPOINTS.FILE_ENDPOINT,
            { params: { url } }
        );
        yield fork(getFiles);
        yield put(removeFileSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

export default function* filesSaga() {
    yield takeLatest(TYPES.GET_FILE_REQUEST, getFile);
    yield takeLatest(TYPES.GET_FILES_REQUEST, getFiles);
    yield takeLatest(TYPES.CREATE_FILE_REQUEST, createFile);
    yield takeLatest(TYPES.REMOVE_FILE_REQUEST, removeFile);
}
