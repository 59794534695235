import classNames from "classnames";
import React, { useEffect, useState } from "react";

import "./radio-buttons.sass";

export const RadioButtons = ({ title, items, obj, field, active }) => {
    const [activeItem, setActiveItem] = useState(active);

    useEffect(() => setActiveItem(active), [active]);

    return (
        <div className="radiobuttons__wrapper">
            <h3 className="radiobuttons__title">{title}</h3>
            <div className="radiobuttons__items">
                {items.map((item, idx) => (
                    <div
                        className={classNames("radiobuttons__item", {
                            active: activeItem === item,
                        })}
                        onClick={() => {
                            setActiveItem(item);
                            obj[field] = item;
                        }}
                        key={idx.toString()}
                    >
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};
