import React from "react";

import "./file-loading.sass";

export const FileLoading = ({ filesSize, progress }) => (
    <div className="file__loading">
        <p>{filesSize}</p>
        <p>{progress}</p>
    </div>
);
