import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Navmenu } from "../../components/navmenu";
import { Input } from "../../components/input";
import { Options } from "../../components/options";
import { Date } from "../../components/date";
import { FormButtons } from "../../components/form-buttons";

import { stickDate } from "../../utils/stick-date";
import { createLinkRequest } from "../../store/links/actions";

export const CreateLink = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const dateNow = new window.Date();
    const [values, setValues] = useState({
        clicks_count: "0",
        is_admin: false,
    });
    const [date, setDate] = useState({
        year: dateNow.getFullYear(),
        month: dateNow.getMonth() + 1,
        day: dateNow.getDate(),
        hour: dateNow.getHours(),
        minute: dateNow.getMinutes(),
        second: dateNow.getSeconds(),
    });

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    const onCreate = () => {
        dispatch(
            createLinkRequest(stickDate(values, date, "date_of_creation"))
        );
        history.goBack();
    };

    return (
        <div className="page">
            <Navmenu />
            <main className="main">
                <form className="content__wrapper">
                    <Input
                        title="Original link"
                        value={values?.original_link}
                        onFieldChange={onFieldChange}
                        name="original_link"
                        placeholder="Original link"
                    />
                    <Input
                        title="Short link"
                        value={values?.short_link}
                        onFieldChange={onFieldChange}
                        name="short_link"
                        placeholder="Short link"
                    />
                    <Input
                        title="Clicks count"
                        value={values?.clicks_count}
                        onFieldChange={onFieldChange}
                        name="clicks_count"
                        placeholder="Clicks count"
                    />
                    <Input
                        title="User IP"
                        value={values?.user_ip}
                        onFieldChange={onFieldChange}
                        name="user_ip"
                        placeholder="User IP"
                    />
                    <Options
                        title="For the admin?"
                        isActive={values?.is_admin}
                        setIsActive={onFieldChange}
                        name="is_admin"
                    />
                    <Date
                        date={values?.date_of_creation}
                        values={date}
                        setValues={setDate}
                        disableOffset
                    />
                    <FormButtons onCreate={onCreate} />
                </form>
            </main>
        </div>
    );
};
