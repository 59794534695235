import React from "react";
import { Button } from "../button";

import "./form-buttons.sass";

export const FormButtons = ({ onCreate, onDelete, onSave }) => (
    <div className="form_buttons__wrapper">
        {onCreate && <Button value="Create" onClick={onCreate} />}
        {onSave && <Button value="Save" onClick={onSave} />}
        {onDelete && (
            <Button value="Delete" onClick={onDelete} className="red" />
        )}
    </div>
);
