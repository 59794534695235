import { all } from "redux-saga/effects";

import linksSaga from "./links/saga";
import loginSaga from "./login/saga";
import usersSaga from "./users/saga";
import filesSaga from "./files/saga";
import codesSaga from "./codes/saga";
import recipessSaga from "./recipes/saga";

export default function* rootSaga() {
    yield all([
        linksSaga(),
        loginSaga(),
        usersSaga(),
        filesSaga(),
        codesSaga(),
        recipessSaga(),
    ]);
}
