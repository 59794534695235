import { TYPES } from "../types";

// Получение файла
export const getFileRequest = (url) => ({
    type: TYPES.GET_FILE_REQUEST,
    url,
});

export const getFileSuccess = ({ files, date }) => ({
    type: TYPES.GET_FILE_SUCCESS,
    files,
    date,
});

// Получение файов
export const getFilesRequest = () => ({
    type: TYPES.GET_FILES_REQUEST,
});

export const getFilesSuccess = (files) => ({
    type: TYPES.GET_FILES_SUCCESS,
    files,
});

// Создание файла
export const createFileRequest = (values, formData) => ({
    type: TYPES.CREATE_FILE_REQUEST,
    values,
    formData,
});

export const createFileSuccess = () => ({
    type: TYPES.CREATE_FILE_SUCCESS,
});

// Удаление файла
export const removeFileRequest = (url) => ({
    type: TYPES.REMOVE_FILE_REQUEST,
    url,
});

export const removeFileSuccess = () => ({
    type: TYPES.REMOVE_FILE_SUCCESS,
});

// Ошибка
export const requestFailure = (error) => ({
    type: TYPES.FAILTURE,
    error,
});
