import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { Loading } from "../../components/loading";
import { Navmenu } from "../../components/navmenu";
import { Input } from "../../components/input";
import { Options } from "../../components/options";
import { Date } from "../../components/date";
import { FormButtons } from "../../components/form-buttons";

import { stickDate } from "../../utils/stick-date";

import { getLinkRequest } from "../../store/links/actions";
import { patchLinkRequest } from "../../store/links/actions";
import { removeLinkRequest } from "../../store/links/actions";

export const Link = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { short } = useParams();

    const { link, isLoading } = useSelector((state) => state.links);
    const [values, setValues] = useState(link);
    const [date, setDate] = useState();

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    useEffect(() => {
        dispatch(getLinkRequest(short));
    }, [dispatch, short]);

    useEffect(() => {
        if (link)
            setValues({
                ...link,
                id: link.id,
                clicks_count: link.clicks_count?.toString(),
            });
    }, [link]);

    const onSave = () => {
        dispatch(patchLinkRequest(stickDate(values, date, "date_of_creation")));
        history.goBack();
    };
    const onDelete = () => {
        dispatch(removeLinkRequest(values.id || short));
        history.goBack();
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="page">
                    <Navmenu />
                    <main className="main">
                        <form className="content__wrapper">
                            <Input
                                title="Original link"
                                value={values?.original_link}
                                onFieldChange={onFieldChange}
                                name="original_link"
                                placeholder="Original link"
                            />
                            <Input
                                title="Short link"
                                value={values?.short_link}
                                onFieldChange={onFieldChange}
                                name="short_link"
                                placeholder="Short link"
                            />
                            <Input
                                title="Clicks count"
                                value={values?.clicks_count}
                                onFieldChange={onFieldChange}
                                name="clicks_count"
                                placeholder="Clicks count"
                            />
                            <Input
                                title="User IP"
                                value={values?.user_ip}
                                onFieldChange={onFieldChange}
                                name="user_ip"
                                placeholder="User IP"
                            />
                            <Options
                                title="For the admin?"
                                isActive={values?.is_admin}
                                setIsActive={onFieldChange}
                                name="is_admin"
                            />
                            <Date
                                date={values?.date_of_creation}
                                values={date}
                                setValues={setDate}
                            />
                            <FormButtons onSave={onSave} onDelete={onDelete} />
                        </form>
                    </main>
                </div>
            )}
        </>
    );
};
