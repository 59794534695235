import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { linksReducer } from "./links";
import { loginReducer } from "./login";
import { usersReducer } from "./users";
import { filesReducer } from "./files";
import { codesReducer } from "./codes";
import { recipesReducer } from "./recipes";
import { messageboxReducer } from "./messagebox";

export const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        links: linksReducer,
        login: loginReducer,
        users: usersReducer,
        files: filesReducer,
        codes: codesReducer,
        recipes: recipesReducer,
        messagebox: messageboxReducer,
    });
