import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Navmenu } from "../../components/navmenu";
import { Textarea } from "../../components/textarea";
import { Button } from "../../components/button";

import { sendSQLRequest } from "../../store/codes/actions";

export const SQL = () => {
    const dispatch = useDispatch();

    const [values, setValues] = useState();

    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    return (
        <div className="page">
            <Navmenu />
            <main className="main">
                <form className="content__wrapper">
                    <Textarea
                        title="SQL request"
                        value={values?.code}
                        onFieldChange={onFieldChange}
                        name="code"
                        placeholder="SQL code"
                    />
                    <Button
                        value="Send"
                        onClick={() => dispatch(sendSQLRequest(values))}
                        className="right"
                    />
                </form>
            </main>
        </div>
    );
};
