import React, { useEffect } from "react";

import { Input } from "../input";

import "./date.sass";

export const Date = ({ date, values, setValues, disableOffset }) => {
    const onFieldChange = (name, value) =>
        setValues({ ...values, [name]: value });

    // Смещение по времени относительно гринвича
    const timeOffset = new window.Date().getTimezoneOffset();
    useEffect(() => {
        if (date) {
            const days = date.split("T")[0].split("-");
            const times = date.split("T")[1].split(".")[0].split(":");
            setValues({
                year: days[0],
                month: days[1],
                day: days[2],
                hour: times[0],
                minute: times[1],
                second: times[2],
            });
        }
    }, [date, setValues]);

    return (
        <div className="date">
            <Input
                title="Year"
                value={values?.year}
                onFieldChange={onFieldChange}
                name="year"
                placeholder="Year"
                className="short"
                titleClass="centred"
            />
            <Input
                title="Month"
                value={values?.month}
                onFieldChange={onFieldChange}
                name="month"
                placeholder="Month"
                className="short"
                titleClass="centred"
            />
            <Input
                title="Day"
                value={values?.day}
                onFieldChange={onFieldChange}
                name="day"
                placeholder="Day"
                className="short"
                titleClass="centred"
            />
            <Input
                title="Hour"
                value={values?.hour}
                onFieldChange={onFieldChange}
                name="hour"
                placeholder="Hour"
                className="short"
                titleClass="centred"
            />
            <Input
                title="Minute"
                value={values?.minute}
                onFieldChange={onFieldChange}
                name="minute"
                placeholder="Minute"
                className="short"
                titleClass="centred"
            />
            <Input
                title="Second"
                value={values?.second}
                onFieldChange={onFieldChange}
                name="second"
                placeholder="Second"
                className="short"
                titleClass="centred"
            />
            {disableOffset ?? (
                <div className="date__offset">
                    {values?.offset > 0
                        ? `-${(timeOffset / 60).toString().replace("+", "")}`
                        : `+${(timeOffset / 60)
                              .toString()
                              .replace("-", "")}`}{" "}
                    hours
                </div>
            )}
        </div>
    );
};
