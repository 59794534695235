import { TYPES } from "../types";

const initialState = {
    messageText: "",
    isSuccess: false,
    isError: false,
};

export const messageboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_MESSAGEBOX_SUCCESS: {
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: action.text,
            };
        }

        case TYPES.SET_MESSAGEBOX_ERROR: {
            return {
                ...state,
                isSuccess: false,
                isError: true,
                messageText: action.text,
            };
        }

        case TYPES.SET_MESSAGEBOX_DEFAULT:
            return {
                ...state,
                isSuccess: false,
                isError: false,
                messageText: "",
            };

        case TYPES.FAILTURE:
            return {
                ...state,
                isSuccess: false,
                isError: true,
                messageText: "Unknown error!",
            };

        case TYPES.CREATE_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: "Link successfully created!",
            };

        case TYPES.PATCH_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: "Link changed successfully!",
            };

        case TYPES.REMOVE_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: "Link successfully deleted!",
            };

        // Users
        case TYPES.CREATE_USER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: "User successfully created!",
            };

        case TYPES.PATCH_USER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: "User changed successfully!",
            };

        case TYPES.REMOVE_USER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: "User successfully deleted!",
            };

        // Files
        case TYPES.CREATE_FILE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: "File successfully created!",
            };

        case TYPES.REMOVE_FILE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                messageText: "File successfully deleted!",
            };

        default:
            return state;
    }
};
