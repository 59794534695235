import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

import { Loading } from "../../components/loading";
import { Navmenu } from "../../components/navmenu";
import { RecordsPanel } from "../../components/records-panel";
import { Record } from "../../components/record";

import { getCodesRequest } from "../../store/codes/actions";
import { filterRecords } from "../../utils/filter-records";

export const Codes = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        dispatch(getCodesRequest());
    }, [dispatch]);

    const { codes, isLoading } = useSelector((state) => state.codes);

    const filteredRecords = filterRecords(codes, searchValue, ["url"]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="page">
                    <Navmenu />
                    <main className="main">
                        <RecordsPanel
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            link="/code/create"
                        />
                        {filteredRecords ? (
                            filteredRecords.map((code, idx) => (
                                <Record
                                    title={code.url}
                                    id={code.id}
                                    onClick={() => (
                                        <Redirect to={`/code/${code.url}`} />
                                    )}
                                    link="code"
                                    key={idx.toString()}
                                />
                            ))
                        ) : (
                            <p className="not__found">Records not found...</p>
                        )}
                    </main>
                </div>
            )}
        </>
    );
};
